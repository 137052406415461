<template>
  <div>
    <ModalLancarFEN @lancaCodigoFEN="lancaCodigoFEN" />
    <ModalConfirmacao
      :vontade="vontade"
      :elementoVontade="elementoVontade"
      :funcaoVontade="datasModalConfirmacao"
      :condicional="!$store.getters.iniciarGravacao"
      @limparGravacao="limparGravacao"
      @cancelarExercicio="concluircancelarExercicio()"
    />
    <div class="mb-4">
      <h1 class="title">
        {{
          `Tipo do Exercício: ${
            $store.getters.getExercicio == 2 ? "Contra StockFish" : "PGN"
          }`
        }}
      </h1>
    </div>
    <div class="d-flex j-c-center position-relative">
      <!-- && $store.getters.getExercicio == 1 -->
      <div
        v-if="
          !$store.getters.getStatusGravacao 
        "
        id="myBoard"
        style="width: 500px"
        class="mb-3"
      ></div>
      <tabuleiroGravaPGN v-else />
      <div
        id="historicoJogadas"
        class="position-absolute"
        v-if="$store.getters.getDadosExercicios.exercicio_categoria_id == 1"
      >
        <div class="row">
          <div
            id="historicoJogadasAluno"
            class="col-md-6 div-jogada"
            :style="
              `background-color: ${
                alunoPeca == 'black' ? '#D5D8DC' : '#F2F3F4'
              }`
            "
          >
            <div class="quemJogou">
              <h2 class="my-2">Aluno</h2>
            </div>
          </div>
          <div
            id="historicoJogadasMaquina"
            class="col-md-6 div-jogada"
            :style="
              `background-color: ${
                alunoPeca == 'white' ? '#D5D8DC' : '#F2F3F4'
              }`
            "
          >
            <div class="quemJogou">
              <h2 class="my-2">Professor</h2>
            </div>
          </div>
          <div id="col-btn-lancaFEN" class="col-md-12">
            <button
              @click="lancaCodigoFENsimulacao()"
              class="bg-0e5caf color-fff"
            >
              Posição Inicial
            </button>
          </div>
        </div>
      </div>
      <!-- <div id="myBoard" :style="$store.getters.getExercicio == 1 ? 'width: 100%' : 'width: 50%'" class="mb-3"></div> -->
    </div>
    <div class="mb-4"></div>
    <!-- <div id="whereShowFen" class="my-4 d-flex j-c-center">
      <span id="spanFEN" class="color-42cc7e f-w-700">{{
        theFEN ? theFEN : "8/8/8/8/8/8/8/8"
      }}</span>
    </div> -->
    <div>
      <btnsGravarJogos
        @limparGravacao="limparGravacao"
        @SetPositionInicial="SetPositionInicial"
        @inciarGravacaoMeutabuleiro="inciarGravacaoMeutabuleiro"
        @concluirExercicio="concluircancelarExercicio"
      />
    </div>
  </div>
</template>

<script>
import "../../public/js/chessboard-1.0.0";
import ModalLancarFEN from "@/components/modais/ModalLancarFEN";
import btnsGravarJogos from "@/components/professor/btnsGravarJogos";
import ModalConfirmacao from "@/components/modais/ModalConfirmacao";
import tabuleiroGravaPGN from "@/components/tabuleiroGravaPGN";
export default {
  data() {
    return {
      arrPGN: [],
      alunoPeca: null,
      fenResolucaoThis: [],
      definitivePGN: [],
      currentPiece: null,
      ourBoard: null,
      jogadas: [],
      store: this.$store,
      arrParaResolucaoDoExercicio: [],
      datasModalConfirmacao: ["SET_LIMPAPGN", null],
      vontade: "limpar",
      elementoVontade: "gravação",
      theTabuleiro: null,
    };
  },
  props: ["theFEN"],
  components: {
    ModalLancarFEN,
    btnsGravarJogos,
    ModalConfirmacao,
    tabuleiroGravaPGN,
  },
  created() {
    this.alunoPeca = this.$store.getters.getDadosExercicios.cor;
  },
  mounted() {
    var that = this;
    var config = {
      draggable: true,
      dropOffBoard: "trash",
      sparePieces: true,
      showNotation: true,
      onDrop: onDrop,
      onDragStart: this.$store.getters.getExercicio == 1 ? onDragStart : null,
    };
    var game = new Chess();
    var arrPGNtoSave = this.arrPGN;

    var IniciaGravacao = false;
    var thePiece = null;
    var jogadaFrom = null;
    var jogadaTo = null;
    var fensResolucao = this.fenResolucaoThis;
    var definitive = this.definitivePGN;
    var store = this.$store;
    var board = Chessboard("myBoard", config);
    var $status = $("#status");
    var $fen = $("#fen");
    var $pgn = $("#pgn");
    var verificaFENteste = [];

    // $("#startBtn").on("click", board.start);
    // $("#clearBtn").on("click", board.clear);
    // $("#gerarFen").on("click", getFenCode);

    this.ourBoard = board;

    let dadosParaEdicao = this.$store.getters.getEditarDadosExercicio;
    console.log(">>>>", dadosParaEdicao);
    if (dadosParaEdicao.id) {
      board.position(dadosParaEdicao.fen);
    }

    function onDragStart(source, piece, position, orientation) {
      // do not pick up pieces if the game is over
      if (game.game_over()) return false;

      // only pick up pieces for the side to move
      if (
        (game.turn() === "w" && piece.search(/^b/) !== -1) ||
        (game.turn() === "b" && piece.search(/^w/) !== -1)
      ) {
        return false;
      }
    }

    function getFenCode() {
      document.getElementById("spanFEN").innerHTML = board.fen();
      store.commit("SET_CODIGOFEN", board.fen());
    }

    function getFenPgnCodeResolucao(valFEN) {
      let valor = false;
      let verificar = store.getters.getMeuTabuleiro.fen
        ? store.getters.getMeuTabuleiro.fen
        : fensResolucao;
      if (store.getters.getStatusGravacao) {
        if (store.getters.getMeuTabuleiro.status) {
          fensResolucao.push(store.getters.getMeuTabuleiro.fen);
          store.commit(
            "SET_MEU_TABULEIRO",
            (store.getters.getMeuTabuleiro.status = false)
          );
        }
        if (verificar.length > 0) {
          if (verificar[verificar.length - 1] != valFEN) {
            valor = true;
            let verificador =
              that.arrParaResolucaoDoExercicio.length > 0
                ? that.arrParaResolucaoDoExercicio[
                    that.arrParaResolucaoDoExercicio.length - 1
                  ]
                : that.arrParaResolucaoDoExercicio[0];
            let elIdJogada = null;
            console.log(that.arrParaResolucaoDoExercicio.length);
            if (that.arrParaResolucaoDoExercicio.length % 2 == 0) {
              elIdJogada = "historicoJogadasMaquina";
            } else {
              elIdJogada = "historicoJogadasAluno";
            }
            criarBotaoJogadas(verificador, elIdJogada, valFEN);
          } else {
          }
        } else {
          valor = true;
        }
      } else {
        fensResolucao = [];
        store.commit("SET_CODIGOFEN", fensResolucao);
      }
      return new Promise((resolve) => {
        resolve({ valid: valor });
      });
    }
    // posicaoArrFen = null,
    function criarBotaoJogadas(jogada, elId, fenDaJogada) {
      var elemento_pai = document.getElementById(elId);
      var elemento = document.createElement("button");
      var texto = document.createTextNode(jogada);
      elemento.style.backgroundColor = "rgb(250,250,250)";
      elemento.style.marginTop = "2px";
      elemento.style.width = "60px";
      elemento.style.height = "30px";

      elemento.appendChild(texto);
      elemento.setAttribute("class", "jogada");
      elemento.onclick = function() {
        board.position(fenDaJogada);
      };

      elemento_pai.appendChild(elemento);
    }

    function onDragStart(
      source,
      target,
      piece,
      position,
      orientation,
      newPos,
      oldPos
    ) {
      if (store.getters.getStatusGravacao) {
        jogadaFrom = source;
      }
    }

    function onDrop(source, target, piece, newPos, oldPos, orientation) {
      // that.meuTabuleiroStock(board.fen());
      if (store.getters.getStatusGravacao) {
        var move = game.move({
          from: source,
          to: target,
        });
        config.onDragStart = onDragStart;
        config.onDragStart = onDragStart;
        // illegal move
        if (move === null) return "snapback";

        updateStatus();
      }

      if (store.getters.getDadosExercicios.exercicio_categoria_id == 1) {
        if (!store.getters.getStatusGravacao) {
          that.arrParaResolucaoDoExercicio = [];
        } else {
          that.arrParaResolucaoDoExercicio.push(`${piece}${target}`);
        }
        // let theFEN = null;
        // console.log("arrParaResolucaoDoExercicio", arrParaResolucaoDoExercicio);
        setTimeout(() => {
          that.theFEN = board.fen();
          setTimeout(() => {
            getFenPgnCodeResolucao(that.theFEN).then((resolve) => {
              if (resolve.valid) {
                fensResolucao.push(that.theFEN);
                jogadaTo = target;
                arrPGNtoSave.push(`${jogadaFrom}-${jogadaTo}`);
                arrPGNtoSave.forEach((element, index) => {
                  if (element.match("Obj")) arrPGNtoSave.splice(index, 1);
                });
                store.commit("SET_CODIGOPGN", that.arrParaResolucaoDoExercicio);
                store.commit("SET_CODIGOFEN", fensResolucao);
              }
            });
          }, 150);
        }, 100);
      } else {
        setTimeout(() => {
          console.log('objToPost["fen"]', board.fen());
          store.commit("SET_CODIGOFEN", board.fen());
        }, 250);
      }
    }
    function onSnapEnd() {
      board.position(game.fen());
    }

    function updateStatus() {
      var status = "";

      var moveColor = "White";
      if (game.turn() === "b") {
        moveColor = "Black";
      }

      // checkmate?
      if (game.in_checkmate()) {
        status = "Game over, " + moveColor + " is in checkmate.";
      }

      // draw?
      else if (game.in_draw()) {
        status = "Game over, drawn position";
      }

      // game still on
      else {
        status = moveColor + " to move";

        // check?
        if (game.in_check()) {
          status += ", " + moveColor + " is in check";
        }
      }

      $status.html(status);
      $fen.html(game.fen());
      $pgn.html(game.pgn());
    }

    updateStatus();

    function showMovimentos() {
      let arrTemp = store.getters.getcodigoPGN;
      let arrStore = [];
      setInterval(() => {
        if (arrTemp.length != 0) {
          board.move(arrTemp[0]);
          // console.log(arrTemp);
          arrStore.push(arrTemp[0]);
          arrTemp.splice(0, 1);
        } else {
          return;
        }
        store.commit("SET_CODIGOPGN", arrStore);
        // console.log("store.getters.getcodigoPGN", store.getters.getcodigoPGN);
      }, 1000);
    }

    var botaoSimularJogadas = document.getElementById("simularJogo");
    botaoSimularJogadas.onclick = function() {
      showMovimentos();
    };
  },
  methods: {
    lancaCodigoFEN(val) {
      if (
        val &&
        this.$store.getters.getDadosExercicios.exercicio_categoria_id == 1
      ) {
        this.ourBoard.position(val);        
        this.inciarGravacaoMeutabuleiro()
      } else if (val) {
        this.ourBoard.position(val);        
        this.inciarGravacaoMeutabuleiro()
        this.$store.commit("SET_CODIGOFEN", this.ourBoard.fen());
        this.theFEN = val;
      }
      this.hideModal("modalNameModalLancarCodioFen");
    },
    // meuTabuleiroStock(val) {
    //   this.$store.commit("SET_CODIGOFEN", val);
    //   console.log(".......", val);
    // },
    lancaCodigoFENsimulacao() {
      let verificar = this.$store.getters.getMeuTabuleiro.fen;

      if (verificar) {
        this.ourBoard.position(verificar);
      } else {
        this.ourBoard.position(this.fenResolucaoThis[0]);
      }
    },
    limparGravacao() {
      if (this.theTabuleiro) {
        this.ourBoard.position(this.theTabuleiro);
      } else {
        this.ourBoard.position("8/8/8/8/8/8/8/8");
      }

      this.$store.dispatch("resetCamposStore");
      this.arrParaResolucaoDoExercicio = [];
    },
    verificaStatusExercicios() {
      return this.$store.getters.getExercicio == 1 &&
        this.$store.getters.getStatusGravacao == true
        ? true
        : false;
    },
    atualizaPGNstore(val) {
      this.$store.commit("SET_CODIGOPGN", val);
    },
    SetPositionInicial() {
      let val = "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR";
      this.ourBoard.position(val);
      this.inciarGravacaoMeutabuleiro()
    },
    inciarGravacaoMeutabuleiro() {
      if (this.ourBoard.fen() == "8/8/8/8/8/8/8/8") {
        this.toastGlobal("error", "O tabuleiro não pode estar vazio.");
      } else {
        this.$store.commit("SET_MEU_TABULEIRO", {
          status: true,
          fen: this.ourBoard.fen(),
        });
        this.theTabuleiro = this.ourBoard.fen();
        this.$store.commit("SET_CODIGOFEN", this.ourBoard.fen());
        this.$store.commit("SET_STATUS_GRAVACAO_EXERCICIO", true);
      }
    },
    concluircancelarExercicio() {
      this.elementoVontade = "exercício";
      this.$emit("changeCP");
      this.$store.commit("SET_STATUS_GRAVACAO_EXERCICIO", true);
      this.$store.commit("SET_EDITA_EXERCICIO", {});
      this.showModal("modalNameConfirmacao");
    },
  },
};
</script>

<style scoped>
@import "../../public/css/chessboard-1.0.0.min.css";
#spanFEN {
  padding: 10px 150px;
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

span {
  font-size: 12px;
}

#historicoJogadas {
  height: 100%;
  width: 200px;
  top: 0;
  right: 0;  
}

#historicoJogadas > div {
  height: 100%;
  width: 200px;
}

#historicoJogadasMaquina,
#historicoJogadasAluno{
  height: 100%;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  height: 5px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #454c57;
  -webkit-box-shadow-sm: inset 0 0 6px #edf0f5;
}


.div-jogada {
  width: 200px;
  height: 100%;
  border: 1px solid #c0c0c0;
}

.quemJogou h2 {
  font-size: 14px;
}

#col-btn-lancaFEN {
  margin-top: -15px;
}

#col-btn-lancaFEN button {
  height: 30px;
  width: 100%;
  font-size: 12px;
}
</style>
