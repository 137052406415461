<template>
  <div id="Aluno_Meus_Talentos" class="pl-1">
    <ModalNovoExercicio @recebeDadosDaModal="recebeDadosDaModal()" :inPage="true" />

    <h2 class="mb-3 title main-title w-100">Criando Exercício</h2>
    <div class="here pb-5">
      <div class="container in-here bg-fff shadow">
        <h2 class="color454C57 pt-4">
          {{ getDadosExercicios.name }} - Exercício {{ setTitleExercicio() }}
        </h2>
        <div class="row pb-5 px-3 pt-4">
          <!-- <div :class="`bord-r ${$store.getters.getExercicio == 1 ? 'col-md-7' : 'col-md-12'} text-center bg-fff px-4`"> -->
          <div :class="`bord-r col-md-12`">
            <div class="tabuleiro position-relative">
              <div class="div-tabuleiro-img">
                <component
                  :is="atualComponente"
                  :theFEN="codigoFEN"
                  @enviaCoordenadaPeca="recebeCoordenadaPeca"
                  @changeCP="changeCP"
                  :pecaAluno="pecaAluno"
                />
                <!-- <img src="@/assets/aluno/tabuleiro.png" alt="tabuleiro.png"> -->
              </div>
            </div>
          </div>
          <div class="col-md-5" v-if="$store.getters.getExercicio == 4">
            <!--caso necessário-->
            <h3>Planilha</h3>
            <div class="row">
              <div class="col-md-6 mt-3 text-center">
                <h3>Você</h3>
              </div>
              <div class="col-md-6 mt-3 text-center">
                <h3>Aluno</h3>
              </div>
            </div>
            <div
              class="border-default pr-4 pl-4 pb-4 pt-2 scrollable shape-historico-dica mb-4 shadow"
              style="height: 90%"
            >
              <div class="row" v-for="(item, index) in coordenadasPGN" :key="index">
                <div class="col-md-6">
                  <div class="row position-relative">
                    <div
                      class="col-md-8 mx-auto justify-content-between where-historico-de-jogadas-aluno position-relative"
                    >
                      <div class="jogada d-flex justify-content-between pt-3">
                        <div id="coordenadaPecaProfessor">
                          <span>{{ item }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row position-relative">
                    <div
                      class="col-md-8 mx-auto justify-content-between where-historico-de-jogadas-professor"
                    >
                      <div class="jogada d-flex justify-content-between pt-3">
                        <div id="coordenadaPecaAluno">
                          <span>{{
                            verificaArr(index) ? coordenadasPGN[index + 2] : null
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalNovoExercicio from "@/components/modais/ModalNovoExercicio";
import theTabuleiro from "@/components/theTabuleiro";
import theTabuleiroMontaProfessor from "@/components/theTabuleiroMontaProfessor";
import coordenadas from "@/mixins/coordenadas";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      positionX: null,
      positionY: null,
      coordenadasPGN: this.$store.getters.getcodigoPGN,
      atualComponente: null,
      codigoFEN: null,

      statusSalvar: "salvar",
      show: false,
    };
  },
  mixins: [coordenadas],
  components: {
    ModalNovoExercicio,
    theTabuleiro,
    theTabuleiroMontaProfessor,
  },
  computed: {
    ...mapGetters(["getDadosExercicios"]),
  },
  mounted() {
    if (this.$store.getters.getExercicio == 3) {
      // this.showModal("modalNamemodalCodioFen");
      this.atualComponente = theTabuleiro;
    } else {
      this.atualComponente = theTabuleiroMontaProfessor;
    }
  },
  methods: {
    dragPiece(el) {
      this.resetPositions();
      $(`#${el}`).css({
        "z-index": "99999",
      });
      let element = $(`#${el}`).first();
      this.positionX = element.position().left;
      this.positionY = element.position().top;
    },
    changeCP(val) {
      this.$emit("mudaComponente", "ProfessorPesquisarTema");
    },
    recebeDadosDaModal() {
      // alert("dados recebidos");
      this.hideModal("modalNameNovoExercicio");
    },

    verificaArr(val) {
      return val < this.coordenadasPGN.length ? true : false;
    },
    setTitleExercicio() {
      let numExercicio = [];
      numExercicio = this.getDadosExercicios.cod.split("-");
      numExercicio = parseInt(numExercicio[1], 10) + 1;
      return `${numExercicio < 10 ? `0${numExercicio}` : numExercicio}`;
    },
  },
};
</script>

<style scoped>
* {
  transition: 0s;
}
</style>
