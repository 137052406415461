<template>
  <transition-group name="fade" key="key_trasition_professor_pesquisa_tema">
    <div class="here py-5" v-if="show" key="pesquisartemas">
      <div class="positio-absolute" v-if="showLoader">
        <Loader />
      </div>

      <div
        class="container bg-fff in-here border-default shadow-sm position-relative"
      >
        <div class="row pb-4">
          <div class="lupa col-md-11 mx-auto text-center d-flex my-4 px-0">
            <div class="position-relative">
              <img
                class="position-absolute opacity-more"
                src="@/assets/icones/lupa.png"
                alt="lupa.png"
              />
              <input
                v-model="inputPesquisar"
                type="text"
                placeholder="Digite o nome ou o código para pesquisar"
                class="border-default shadow-sm-2"
              />
            </div>
          </div>
          <div class="col-md-11 mx-auto text-left px-0">
            <h2 class="title-dados pb-2 mt-2">
              Temas e exercícios Cadastrados
            </h2>
          </div>
        </div>
        <Loader v-if="loader" />
        <div>
          <AdministradorProfessorTemas
            :dadosTemaExercicio="dadosTemaExercicio"
            @AvoMudarCP="emitParaMudarCP('ProfessorAdicionaExercicioComponent')"
            @atualizaDados="atualizaDados"
            :mt="'mt-3'"
          />
        </div>
      </div>
    </div>
  </transition-group>
</template>

<script>
import AdministradorProfessorTemas from "@/components/administrador/AdministradorProfessorTemas";
import Loader from "@/components/Loader";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      show: false,
      showLoader: true,
      idTema: null,
      inputPesquisar: "",
      dadosTemaExercicio: [],
    };
  },
  computed: {
    ...mapGetters(["getTemasExercicios"]),
  },
  mounted() {
    this.show = true;

    setTimeout(() => {
      setTimeout(() => {
        this.dadosTemaExercicio = this.filtrosDePesquisaDados("", "exercicios");
      }, 550);
      this.pegaTemas();
    }, 2000);
  },
  components: {
    AdministradorProfessorTemas,
    Loader,
  },
  watch: {
    inputPesquisar: function() {
      this.dadosTemaExercicio = this.filtrosDePesquisaDados(
        this.inputPesquisar,
        "exercicios"
      );
    },
    // getTemasExercicios: function(){
    //   this.atualizaDados()
    // }
  },
  methods: {
    atualizaDados() {
      this.pegaTemas();
      this.dadosTemaExercicio = this.filtrosDePesquisaDados(
        this.inputPesquisar,
        "exercicios"
      );
    },
    pegaTemas() {
      this.$store
        .dispatch("getTemas", {
          token: this.$store.getters.getPerfilToken,
          page: 1,
        })
        .then((resolve) => {
          this.dadosTemaExercicio = resolve.msg;
          if (resolve.valid) {
            this.showLoader = false;
            this.toastGlobal(
              "success",
              `Lista de exercícios atualizada com sucesso`
            );
          } else {
            this.toastGlobal(
              "error",
              `Algo de errado ocorreu, ${resolve.msg.responseJSON.msg}`
            );
          }
        });
    },
    emitParaMudarCP(val) {
      this.$emit("mudaComponente", val);
    },
  },
};
</script>

<style scoped>
input {
  height: 40px;
  border: 1px solid #e4e5e8;
}

.border-bottom {
  border-bottom: 1px solid #e4e5e8;
}

.lupa > div {
  width: 100%;
}

.lupa img {
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
</style>
