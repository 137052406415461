<template>
  <div class="row align-items-center flex-wrap justify-content-around shadow">
    <modal
      id="modalOpcoesMontaTabuleiro"
      name="modalOpcoesMontaTabuleiro"
      :height="'auto'"
      :width="'35%'"
      :adaptive="true"
      :draggable="true"
    >
      <div class="py-4 col-md-12  ">
        <button
          class="bg-0e5caf btn-style-default-less color-fff mx-2"
          @click="setPosicaoPadrao()"
        >
          Posição Inicial
        </button>
        <button
          class="bg-0e5caf btn-style-default-less color-fff mx-2"
          @click="lancaFEN()"
        >
          Lançar FEN
        </button>
        <button
          class="bg-0e5caf btn-style-default-less color-fff mx-2 mt-2"
          @click="meuTabuleiro()"
        >
          Meu Tabuleiro
        </button>
      </div>
    </modal>
    <div class="col-md-8 mx-auto pb-5">
      <h3
        class="mb-3"
        v-if="$store.getters.getDadosExercicios.exercicio_categoria_id == 1"
      >
        <i
          :class="
            `fas fa-video mr-2 ${
              getStatusGravacao ? 'color-42cc7e' : 'color-d42338'
            }`
          "
        ></i>
        {{ getStatusGravacao ? "Gravando Jogadas" : "Gravar Jogadas" }}
      </h3>
      <div class="row j-c-center">
        <div class="col-md-3 my-3">
          <div class="d-flex align-items-center justify-content-center">
            <button class="mb-4 bg-fff" @click="cancelarGravacao()">
              <div
                class="shape bg-0e5caf d-flex align-items-center justify-content-center"
              >
                <i class="fas fa-times color-fff"></i>
              </div>
            </button>
          </div>
          <h4>Cancelar</h4>
        </div>
        <div
          class="col-md-3 my-3"
          v-if="$store.getters.getDadosExercicios.exercicio_categoria_id == 1"
        >
          <div class="d-flex align-items-center justify-content-center">
            <button class="mb-4 bg-fff" @click="limpaGravacao()">
              <div
                class="shape bg-0e5caf d-flex align-items-center justify-content-center"
              >
                <i class="fas fa-redo color-fff"></i>
              </div>
            </button>
          </div>
          <h4>Limpar gravação</h4>
        </div>
        <div class="col-md-3 my-3">
          <div class="d-flex align-items-center justify-content-center">
            <button
              class="mb-4 bg-fff"
              @click="iniciarGravacao()"
              :disabled="$store.getters.getStatusGravacao"
            >
              <div
                class="shape bg-0e5caf d-flex align-items-center justify-content-center"
              >
                <i class="fas fa-play color-fff"></i>
              </div>
            </button>
          </div>
          <h4>Iniciar</h4>
        </div>
        <div class="col-md-3 my-3">
          <div class="d-flex align-items-center justify-content-center">
            <button
              class="mb-4 bg-fff"
              @click="concluirGravacao()"
              :disabled="
                !$store.getters.getStatusGravacao &&
                  $store.getters.getDadosExercicios.exercicio_categoria_id != 2
              "
            >
              <div
                class="shape bg-0e5caf d-flex align-items-center justify-content-center"
              >
                <i class="fas fa-check color-fff"></i>
              </div>
            </button>
          </div>
          <h4>Concluir</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "btnsGravarJogos",
  data() {
    return {
      statusGravacao: "Gravar Jogadas:",
    };
  },
  computed: {
    ...mapGetters(["getStatusGravacao"]),
  },
  methods: {
    limpaGravacao() {
      if (this.$store.getters.getcodigoPGN.length != 0) {
        this.showModal("modalNameConfirmacao");
      } else {
        {
          this.toastGlobal(
            "error",
            `Você não possui jogadas gravadas, clique em iniciar gravação para salvar.`
          );
        }
      }
    },
    lancaFEN() {
      this.showModal("modalNameModalLancarCodioFen");
      this.hideModal("modalOpcoesMontaTabuleiro");
    },
    iniciarGravacao() {
      this.showModal("modalOpcoesMontaTabuleiro");
    },
    concluirGravacao() {
      let dados = this.$store.getters.getEditarDadosExercicio;
      this.$store.commit("SET_STATUS_GRAVACAO_EXERCICIO", false);
      let objToPost = this.$store.getters.getDadosExercicios;
      objToPost["step"] = 1;
      objToPost["cod"] = dados.id
        ? dados.cod
        : this.$store.getters.getCodigoGeradoNovoExercicio;
      if (this.$store.getters.getDadosExercicios.exercicio_categoria_id == 1) {
        objToPost["fen"] = this.$store.getters.getcodigoFENparaResolucao[0];
        objToPost["tema_id"] = dados.id
          ? dados.tema_id
          : this.$store.getters.getTemaID;
        objToPost[
          "resolucao"
        ] = this.$store.getters.getcodigoFENparaResolucao.toString();
        objToPost["PGN"] = this.$store.getters.getcodigoPGN.toString();
      } else {
        objToPost["fen"] = this.$store.getters.getcodigoFENparaResolucao;
        objToPost["tema_id"] = dados.id
          ? dados.tema_id
          : this.$store.getters.getTemaID;
        objToPost["resolucao"] = "livre resolução";
        objToPost["PGN"] = "livre resolução";
      }
      return new Promise((resolve, reject) => {
        this.statusSalvar = "";
        this.show = true;

        $.ajax({
          type: dados.id ? "PUT" : "POST",
          url: `${this.VUE_APP_CMS}api/exercicios${
            dados.id ? `/${dados.id}` : ""
          }`,
          data: objToPost,
          dataType: "json",
          headers: {
            Authorization: this.$store.getters.getPerfilToken,
          },
          success: (response) => {
            this.$store.dispatch("getTemas", {
              token: this.$store.getters.getPerfilToken,
            });
            resolve({ valid: true, msg: response });
            this.$emit("concluirExercicio");
            this.toastGlobal("success", `Exercício cadadastrado com sucesso`);

            this.statusSalvar = "Salvar";
            this.show = false;
          },
          error: (response) => {
            resolve({ valid: false, msg: response });
            this.toastGlobal(
              "error",
              `Aldo de errado ocorreu, ${resolve.statusText}`
            );

            this.statusSalvar = "Salvar";
            this.show = false;
          },
        });
      });
    },
    setPosicaoPadrao() {
      this.$emit("SetPositionInicial");
      setTimeout(() => {
        this.meuTabuleiro()
      }, 250);
    },
    meuTabuleiro() {
      this.$emit("inciarGravacaoMeutabuleiro");
      this.hideModal("modalOpcoesMontaTabuleiro");
    },
    cancelarGravacao() {
      this.$emit("concluirExercicio");
      this.$store.dispatch("resetCamposStore");
    },
  },
};
</script>

<style scoped>
i {
  font-size: 18px;
}

.btns {
  bottom: 0;
}

.icon-prev {
  margin-right: 10px;
}

.nav-prev-next .icon-next {
  margin-left: 10px;
}

.row-pecas {
  margin-top: 40px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 18px !important;
}

h4 {
  font-size: 14px;
}

h5 {
  font-size: 12px !important;
}

.where-historico-de-jogadas-aluno {
  height: 150px;
}

.arrow-proxima-jogada {
  left: -19px;
  bottom: -15px;
}

.shape {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.div-tabuleiro-img img {
  width: 100%;
}

.tentativas {
  font-size: 12px;
}

.btn-style-default-less-2 {
  font-size: 14px;
}

.peca img {
  transform: scale(0.9);
}

.peca img:hover {
  cursor: pointer;
  /* transform: scale(1.1) rotateZ(-10deg);*/
  transition: 0.25s;
  transform: rotateZ(-10deg);
}

.escolherPecas h3 {
  font-size: 16px !important;
}

/* .shape-historico-dica {
  height: 250px;
} */
</style>
