<template>
  <modal
    id="modalModalLancarCodioFen"
    name="modalNameModalLancarCodioFen"
    :height="'auto'"
    :width="'60%'"
    :adaptive="true"
    :draggable="true"
  >
    <div class="border-default d-flex p-4 position-relative">
      <div class="input-group w-100">
        <input
          id="txbFEN"
          type="text"
          class="form-control"
          placeholder="Código FEN do Exercício"
          aria-label="Código FEN do Exercício"
          v-model="codigoFEN"
          aria-describedby="basic-addon2"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary"
            type="button"
            @click="lancarCodigoFEN(codigoFEN)"
          >
            Lançar
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  data() {
    return {
      codigoFEN: null,
    };
  },
  methods: {
    lancarCodigoFEN() {
      this.$emit("lancaCodigoFEN", this.codigoFEN);
      this.$store.commit("SET_STATUS_GRAVACAO_EXERCICIO", true);
    },
  },
};
</script>

<style></style>
