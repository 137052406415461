<template>
  <div id="Coordenador_Progresso_Turmas" class="pl-1">
    <component :is="componentEmQuestao" @mudaComponente="changeCP" />
  </div>
</template>

<script>
import $ from "jquery";
import ProfessorPesquisarTema from "../components/professor/Professor_Pesquisar_Tema";
import ProfessorAdicionaExercicioComponent from "../components/professor/ProfessorAdicionaExercicioComponent";
export default {
  data() {
    return {
      componentEmQuestao: ProfessorPesquisarTema,
    };
  },
  mounted() {
     
  },
  components: {
    ProfessorPesquisarTema,
    ProfessorAdicionaExercicioComponent,
  },
  methods: {
    changeCP(nomeDoComponente) {
      this.componentEmQuestao = nomeDoComponente;
    },
  },
};
</script>

<style scoped></style>
