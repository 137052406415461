<template>
    <div>
        <ModalPromovePeao @executaPromocao="executaPromocao"/>
        <div class="d-flex">
            <div id="myBoard" style="width: 450px"></div>
            <div class="d-flex j-c-center">
                <button v-if="$store.getters.getExercicio == 1" @click="chosePieceToPromotion" class="ml-3 bg-42cc7e">
                    <img src="@/assets/icones/paperPawn.png" alt="icone ">
                </button>
            </div>
        </div>
        <!-- <label>PGN:</label>
        <div id="pgn"></div> -->
    </div>
</template>

<script>
import ModalPromovePeao from '@/components/modais/ModalPromovePeao'
import {mapGetters} from 'vuex'
export default {
    data(){
        return{
            arrPGN: [],
            alunoPeca: null,
            fenResolucaoThis: [],
            definitivePGN: [],
            currentPiece: null,
            ourBoard: null,
            moverPara: {
                from: null,
                to: null
            },
            jogadas: [],
            store: this.$store,
            arrParaResolucaoDoExercicio: [],
            datasModalConfirmacao: ["SET_LIMPAPGN", null],
            vontade: "limpar",
            elementoVontade: "gravação",
            theTabuleiro: null,
            theTarget: null,
            theFEN: null,
            tabuleiroPosicao: null
        }
    },
    created() {
        this.alunoPeca = this.$store.getters.getDadosExercicios.cor;
    },
    computed:{
        ...mapGetters(['getDadosExercicios'])
    },
    mounted(){
        // NOTE: this example uses the chess.js library:
        // https://github.com/jhlywa/chess.js
        var board = null
        var that = this
        var game = new Chess(`${this.$store.getters.getMeuTabuleiro.fen} ${this.alunoPeca[0]} KQkq - 0 1`)
        var $status = $('#status')
        var $fen = $('#fen')
        var $pgn = $('#pgn')
        var arrPGNtoSave = this.arrPGN;
        var jogadaFrom = null;
        var jogadaTo = null;
        var fensResolucao = this.fenResolucaoThis;
        var store = this.$store;
        var cbAluno = []
        var cbProfessor = []
        var promovido = {
            from: null,
            to: null
        }

        this.ourBoard = board;
        function onDragStart (source, piece, position, orientation) {
        // do not pick up pieces if the game is over
            if (game.game_over()) return false

            // only pick up pieces for the side to move
            if ((game.turn() === 'w' && piece.search(/^b/) !== -1) ||
                (game.turn() === 'b' && piece.search(/^w/) !== -1)) {
                return false
            }
            if (store.getters.getStatusGravacao) {
                jogadaFrom = source;
            }
            
        }

        function getFenCode() {
            document.getElementById("spanFEN").innerHTML = board.fen();
            store.commit("SET_CODIGOFEN", board.fen());
        }

        function getFenPgnCodeResolucao(valFEN) {
            let valor = false;
            let verificar = store.getters.getMeuTabuleiro.fen
                ? store.getters.getMeuTabuleiro.fen
                : fensResolucao;
            if (store.getters.getStatusGravacao) {
                if (store.getters.getMeuTabuleiro.status) {
                fensResolucao.push(store.getters.getMeuTabuleiro.fen);
                store.commit(
                    "SET_MEU_TABULEIRO",
                    (store.getters.getMeuTabuleiro.status = false)
                );
                }
                if (verificar.length > 0) {
                if (verificar[verificar.length - 1] != valFEN) {
                    valor = true;
                    let verificador =
                    that.arrParaResolucaoDoExercicio.length > 0
                        ? that.arrParaResolucaoDoExercicio[
                            that.arrParaResolucaoDoExercicio.length - 1
                        ]
                        : that.arrParaResolucaoDoExercicio[0];
                    let elIdJogada = null;
                    if (that.arrParaResolucaoDoExercicio.length % 2 == 0) {
                        elIdJogada = "historicoJogadasAluno";
                    } else {
                        elIdJogada = "historicoJogadasMaquina";
                    }
                    let pgn = null;
                    let tratedPGN = game.pgn().split("]")
                    let a = game.pgn().split("]");
                    let b = a[2] ? a[2].split(".") : a[0].split(".");
                    let cont = 1
                    for (let i = 0; i < b.length; i++) {
                        let c = b[i].split(" ");
                                
                        if (i == b.length - 1) {
                            if(c[1] != undefined){
                                if (cbAluno.length == 0 && cont == 1) {
                                    cbAluno.push(c[1])
                                    criarBotaoJogadas(cbAluno[cbAluno.length - 1], elIdJogada, valFEN);
                                    cont = 0
                                }else{
                                    if (cbAluno[cbAluno.length - 1] != c[1] && cont == 1) {
                                        cbAluno.push(c[1])
                                        criarBotaoJogadas(cbAluno[cbAluno.length - 1], elIdJogada, valFEN);
                                        cont = 0
                                    }
                                }
                                if (!store.getters.getStatusGravacao) {
                                    that.arrParaResolucaoDoExercicio = [];
                                } else {
                                    
                                }
                                if (that.arrParaResolucaoDoExercicio[that.arrParaResolucaoDoExercicio.length - 1] != c[1]) {
                                    that.arrParaResolucaoDoExercicio.push(`${c[1]}`);    
                                }
                                
                            }

                            if(c[2] != undefined){
                                if (cbProfessor.length == 0) {
                                    cbProfessor.push(c[2])
                                    criarBotaoJogadas(cbProfessor[cbProfessor.length - 1], elIdJogada, valFEN);
                                    cont = 0
                                }else{
                                    if (cbProfessor[cbProfessor.length - 1] != c[2]) {
                                        cbProfessor.push(c[2])
                                        criarBotaoJogadas(cbProfessor[cbProfessor.length - 1], elIdJogada, valFEN);
                                        cont = 0
                                    }
                                }
                                if (that.arrParaResolucaoDoExercicio[that.arrParaResolucaoDoExercicio.length - 1] != c[2]) {
                                    that.arrParaResolucaoDoExercicio.push(`${c[2]}`);    
                                }
                            }
                        }
                    }                    
                    } 
                    
                } else {
                valor = true;
                }
            } else {
                fensResolucao = [];
                store.commit("SET_CODIGOFEN", fensResolucao);
            }
            return new Promise((resolve) => {
                resolve({ valid: valor });
            });
            }
            // posicaoArrFen = null,
        function criarBotaoJogadas(jogada, elId, fenDaJogada) {
            var elemento_pai = document.getElementById(elId);
            var elemento = document.createElement("button");
            var texto = document.createTextNode(jogada.replace('=', ''));
            // jogada.replace('w', '').replace('b', '').replace('P', '')
            elemento.style.backgroundColor = "rgb(250,250,250)";
            elemento.style.marginTop = "2px";
            elemento.style.width = "60px";
            elemento.style.height = "30px";

            elemento.appendChild(texto);
            elemento.setAttribute("class", "jogada");
            elemento.onclick = function () {
                board.position(fenDaJogada);
            };

            elemento_pai.appendChild(elemento);
        }

        function onDrop (source, target, piece, newPos, oldPos, orientation) {
            
            if (promovido.from) {
                setTimeout(() => {
                    board.position(game.fen())
                    board.move(`${promovido.from}-${promovido.to}`)
                    promovido.from = null
                    promovido.to = null
                }, 500);
            }
        // see if the move is legal
            that.theTarget = target
            var move = game.move({
                from: source,
                to: target,
                promotion: that.pecaPromocao // NOTE: always promote to a queen for example simplicity
            })

            setTimeout(() => {
            }, 3000);

            
            if (store.getters.getDadosExercicios.exercicio_categoria_id == 1 && move != null) {
                
                // let theFEN = null;
                
                setTimeout(() => {
                    that.theFEN = board.fen();
                    setTimeout(() => {
                    getFenPgnCodeResolucao(that.theFEN).then((resolve) => {
                        if (resolve.valid) {
                        fensResolucao.push(that.theFEN);
                        jogadaTo = target;
                        arrPGNtoSave.push(`${jogadaFrom}-${jogadaTo}`);
                        arrPGNtoSave.forEach((element, index) => {
                            if (element.match("Obj")) arrPGNtoSave.splice(index, 1);
                        });
                        store.commit("SET_CODIGOPGN", that.arrParaResolucaoDoExercicio);
                        store.commit("SET_CODIGOFEN", fensResolucao);
                        }
                    });
                    }, 150);
                }, 100);
                } else {
                setTimeout(() => {
                    store.commit("SET_CODIGOFEN", board.fen());
                }, 250);
                }

            // illegal move
            if (move === null) return 'snapback'

            updateStatus()
        }

        // update the board position after the piece snap
        // for castling, en passant, pawn promotion
        function onSnapEnd () {
            board.position(game.fen())
        }

        function updateStatus () {
            var status = ''

            var moveColor = 'White'
            if (game.turn() === 'b') {
                moveColor = 'Black'
            }

            // checkmate?
            if (game.in_checkmate()) {
                status = 'Game over, ' + moveColor + ' is in checkmate.'
            }

            // draw?
            else if (game.in_draw()) {
                status = 'Game over, drawn position'
            }

            // game still on
            else {
                status = moveColor + ' to move'

                // check?
                if (game.in_check()) {
                status += ', ' + moveColor + ' is in check'
                }
            }

            that.theTabuleiro = board

            $status.html(status)
            $fen.html(game.fen())
            $pgn.html(game.pgn())
        }

        var config = {
            draggable: true,
            position: `${this.$store.getters.getMeuTabuleiro.fen} KQkq - 0 1`,
            onDrop: onDrop,
            onSnapEnd: onSnapEnd,
            dropOffBoard: "trash",
            sparePieces: true,
            showNotation: true,
            onDragStart: this.$store.getters.getExercicio == 1 ? onDragStart : null,
        }
        board = Chessboard('myBoard', config)

        updateStatus()
    },
    components:{
        ModalPromovePeao
    },
     methods:{
         executaPromocao(val){
            if (this.$route.path != '/login') {                
                
                this.hideModal('modalNamePromovePeao')

                    this.pecaPromocao = `${val}`
                setTimeout(() => {
                }, 200);    
            }
        },
        chosePieceToPromotion(){
            this.showModal('modalNamePromovePeao')
        }
     }
}
</script>

<style scoped>
    button{
        height: 50px;
        width: 50px;
        border-radius: 100%!important;
    }

    button img{
        width: 30px;
    }
</style>